import { Component, OnInit } from '@angular/core';
import { MovieService } from '../../shared/movie.service';
//import { BannerserviceService } from 'src/app/shared/bannerservice.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-movie',
  templateUrl: './movie.component.html',
  styleUrls: ['./movie.component.css']
})
export class MovieComponent implements OnInit {

  Movie:any = [];
  successmsg = false;
  folderUrl = environment.apiMainUrl;

  constructor(private apiService: MovieService) {
     this.readMovie();
   }

  ngOnInit() {}

  readMovie(){
    this.apiService.getMovies().subscribe((data) => {
     this.Movie = data['data'];
     //console.log("banner",this.Movie)
    })
  }

  removeMovie(Movie, index) {
    if(window.confirm('Are you sure?')) {
        this.apiService.deleteMovie(Movie._id).subscribe((data) => {
          this.Movie.splice(index,1);
          this.successmsg = true;
        }
      )
    }
  }

}
