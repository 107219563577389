import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";

import { UserService } from "../shared/user.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private userService : UserService,private router : Router){}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        //console.log('json',req.headers);
        //console.log("token",this.userService.getToken());
        if (req.headers.get('accept'))
            return next.handle(req.clone());
        else {
           // console.log("token",this.userService.getToken());
            const clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + this.userService.getToken())
                //.set("Accept","application/json")
                // headers :new HttpHeaders({
                //     'Accept':  'application/json',
                //     'Authorization': `Bearer ${this.userService.getToken()}`
                // })                     
            });
            //console.log(clonedreq);
            return next.handle(clonedreq).pipe(
                tap(
                    event => { },
                    err => {
                        //console.log('Error',err.error.auth);
                        if (err.error.auth == false) {
                            this.router.navigate(['/login']);
                        }
                    })
            );
        }
    }
}