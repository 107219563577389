// built-in
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
// components
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
//routes
import { appRoutes } from './routes';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { UserService } from './shared/user.service';
//other
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PlanComponent } from './components/plan/plan.component';
import { CategoryComponent } from './components/category/category.component';
import { LanguageComponent } from './components/language/language.component';
import { MovieComponent } from './components/movie/movie.component';
import { BannerComponent } from './components/banner/banner.component';
import { CmsComponent } from './components/cms/cms.component';
import { FaqComponent } from './components/fnq/faq.component';
import { JoinRequestComponent } from './components/joinrequest/joinrequest.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AddplanComponent } from './components/addplan/addplan.component';
import { EditplanComponent } from './components/editplan/editplan.component';
import { AddbannerComponent } from './components/addbanner/addbanner.component';
import { EditbannerComponent } from './components/editbanner/editbanner.component';
import { AddcategoryComponent } from './components/addcategory/addcategory.component';
import { EditcategoryComponent } from './components/editcategory/editcategory.component';
import { AddcmsComponent } from './components/addcms/addcms.component';
import { EditcmsComponent } from './components/editcms/editcms.component';
import { AddfaqComponent } from './components/addfaq/addfaq.component';
import { EditFaqComponent } from './components/editfaq/editfaq.component';
import { AddlanguageComponent } from './components/addlanguage/addlanguage.component';
import { EditlanguageComponent } from './components/editlanguage/editlanguage.component';
import { AddmovieComponent } from './components/addmovie/addmovie.component';
import { EditmovieComponent } from './components/editmovie/editmovie.component';
import { UsermanageComponent } from './components/usermanage/usermanage.component';
import { EdituserComponent } from './components/edituser/edituser.component';
import { MovieviewComponent } from './components/movieview/movieview.component';

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    SignUpComponent,
    UserProfileComponent,
    SignInComponent,
    DashboardComponent,
    PlanComponent,
    CategoryComponent,
    LanguageComponent,
    MovieComponent,
    BannerComponent,
    CmsComponent,
    FaqComponent,
    JoinRequestComponent,
    SidebarComponent,
    FooterComponent,
    AddplanComponent,
    EditplanComponent,
    AddbannerComponent,
    EditbannerComponent,
    AddcategoryComponent,
    EditcategoryComponent,
    AddcmsComponent,
    EditcmsComponent,
    AddfaqComponent,
    EditFaqComponent,
    AddlanguageComponent,
    EditlanguageComponent,
    AddmovieComponent,
    EditmovieComponent,
    UsermanageComponent,
    EdituserComponent,
    MovieviewComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    DataTablesModule,
    HttpClientModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },AuthGuard,UserService],
  bootstrap: [AppComponent]
})
export class AppModule { }
