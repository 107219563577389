import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CategoryService } from './../../shared/category.service';
import { Category } from './../../shared/category.model';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'app-editcategory',
  templateUrl: './editcategory.component.html',
  styleUrls: ['./editcategory.component.css']
})
export class EditcategoryComponent implements OnInit {

  category:any =[];
  submitted = false;
  editCatForm: FormGroup;
  CategoryData: Category[];


  constructor(
    public fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private apiService: CategoryService,
    private router: Router
  ) { }

  ngOnInit() {
    this.updateCategory();
    let id = this.actRoute.snapshot.paramMap.get('id');
    this.getCategory(id);
    this.editCatForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, ]]
    })
  }


  get myForm() {
    return this.editCatForm.controls;
  }
  getCategory(id) {
    this.apiService.getCategorys().subscribe((data) => {
      this.category = data['data'];
      for(let categoryData of this.category){
        if(categoryData._id ===id){
          this.editCatForm.setValue({
            name: categoryData.name,
            description: categoryData.description
          });
        }
      }
     }) 
    // this.apiService.getPlan(id).subscribe(data => {
    //   this.editPlanForm.setValue({
    //     name: data['name'],
    //     description: data['description'],
    //     plan_duration_for: data['plan_duration_for'],
    //     plan_duration: data['plan_duration'],
    //     price: data['price'],
    //     currency_type: data['currency_type']
    //   });
    // });
  }

  updateCategory() {
    this.editCatForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, ]]
    })
  }
  onSubmit() {
    this.submitted = true;
    if (!this.editCatForm.valid) {
      return false;
    } else {
      if (window.confirm('Do you want to update the data?')) {
        let id = this.actRoute.snapshot.paramMap.get('id');
        this.apiService.updateCategory(id, this.editCatForm.value)
          .subscribe(res => {            this.router.navigateByUrl('/category');
            //console.log('Content updated successfully!')
          }, (error) => {
            console.log(error)
          })
      }
    }
  }

}
