import { Routes } from '@angular/router';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CategoryComponent } from './components/category/category.component';
import { PlanComponent } from './components/plan/plan.component';
import { LanguageComponent } from './components/language/language.component';
import { MovieComponent } from './components/movie/movie.component';
import { BannerComponent } from './components/banner/banner.component';
import { CmsComponent } from './components/cms/cms.component';
import { JoinRequestComponent } from './components/joinrequest/joinrequest.component';
import { AddplanComponent } from './components/addplan/addplan.component';
import { EditplanComponent } from './components/editplan/editplan.component';
import { AddcategoryComponent } from './components/addcategory/addcategory.component';
import { AddlanguageComponent } from './components/addlanguage/addlanguage.component';
import { AddcmsComponent } from './components/addcms/addcms.component';
import { EditcategoryComponent } from './components/editcategory/editcategory.component';
import { EditlanguageComponent } from './components/editlanguage/editlanguage.component';
import { EditcmsComponent } from './components/editcms/editcms.component';
import { AddfaqComponent } from './components/addfaq/addfaq.component';
import { EditFaqComponent } from './components/editfaq/editfaq.component';
import { AddmovieComponent } from './components/addmovie/addmovie.component';
import { AddbannerComponent } from './components/addbanner/addbanner.component';
import { UsermanageComponent } from './components/usermanage/usermanage.component';
import { EdituserComponent } from './components/edituser/edituser.component';
import { EditbannerComponent } from './components/editbanner/editbanner.component';
import { EditmovieComponent } from './components/editmovie/editmovie.component';
import { FaqComponent } from './components/fnq/faq.component';

export const appRoutes: Routes = [
    {
        path: 'signup', component: UserComponent,
        children: [{ path: '', component: SignUpComponent }]
    },
    {
        path: 'login', component: UserComponent,
        children: [{ path: '', component: SignInComponent }]
    },
    {
        path: 'userprofile', component: DashboardComponent,canActivate:[AuthGuard]
    },
    {
        path: '', redirectTo: '/login', pathMatch: 'full'
    },
    {   path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuard] },
    {   path: 'subscription',component: PlanComponent,canActivate:[AuthGuard]},
    {   path: 'category',component: CategoryComponent,canActivate:[AuthGuard]},
    {   path: 'language',component: LanguageComponent,canActivate:[AuthGuard]},
    {   path: 'movie',component: MovieComponent,canActivate:[AuthGuard]},
    {   path: 'banner',component: BannerComponent,canActivate:[AuthGuard]},
    {   path: 'cms',component: CmsComponent,canActivate:[AuthGuard]},
    {   path: 'faqs',component: FaqComponent,canActivate:[AuthGuard]},
    {   path: 'joinrequest',component: JoinRequestComponent,canActivate:[AuthGuard]},
    {   path: 'addplan', component: AddplanComponent,canActivate:[AuthGuard]},
    {   path: 'usermanage', component: UsermanageComponent,canActivate:[AuthGuard]},
    {   path: 'addlanguage', component: AddlanguageComponent,canActivate:[AuthGuard]},
    {   path: 'editplan/:id', component: EditplanComponent,canActivate:[AuthGuard]},
    {   path: 'editcategory/:id', component: EditcategoryComponent,canActivate:[AuthGuard]},
    {   path: 'editlanguage/:id', component: EditlanguageComponent,canActivate:[AuthGuard]},
    {   path: 'editcms/:id', component: EditcmsComponent,canActivate:[AuthGuard]},
    {   path: 'editfaq/:id', component: EditFaqComponent,canActivate:[AuthGuard]},
    {   path: 'editfaq/:id', component: EditFaqComponent,canActivate:[AuthGuard]},
    {   path: 'editusermanage/:id', component: EdituserComponent,canActivate:[AuthGuard]},
    {   path: 'editbanner/:id', component: EditbannerComponent,canActivate:[AuthGuard]},
    {   path: 'editmovie/:id', component: EditmovieComponent,canActivate:[AuthGuard]},
    {   path: 'addcategory', component: AddcategoryComponent,canActivate:[AuthGuard]},
    {   path: 'addfaq', component: AddfaqComponent,canActivate:[AuthGuard]},
    {   path: 'addbanner', component: AddbannerComponent,canActivate:[AuthGuard]},
    {   path: 'addpage', component: AddcmsComponent,canActivate:[AuthGuard]}
];
