import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class FaqService {
  constructor(private userService: UserService, private http: HttpClient) {}

  headers = new HttpHeaders({
    Accept: "application/json",
    Authorization: `Bearer ${this.userService.getToken()}`,
  });

  createfaq(data): Observable<any> {
    //console.log(this.headers);
    return this.http
      .post(environment.apiBaseUrl + "/faq/insert_faq", data, {
        headers: this.headers,
      })
      .pipe(catchError(this.errorMgmt));
  }

  getfaqs() {
    console.log(this.headers);
    return this.http.get(environment.apiBaseUrl + "/faq/get_all_faq", {
      headers: this.headers,
    });
  }

  getfaq(id): Observable<any> {
    return this.http
      .get(environment.apiBaseUrl + "/faq/get_details/" + id, {
        headers: this.headers,
      })
      .pipe(
        map((res: Response) => {
          return res || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  updatefaq(id: string, data: any): Observable<any> {
    //let url = `${this.baseUri}/updatecategory/${id}`;
    return this.http
      .post(environment.apiBaseUrl + "/faq/update_faq/" + id, data, {
        headers: this.headers,
      })
      .pipe(catchError(this.errorMgmt));
  }

  deletefaq(id: string): Observable<any> {
    //let url = `${this.baseUri}/deletecategory/${id}`;
    return this.http
      .post(
        environment.apiBaseUrl + "/faq/delete_faq",
        { faq_id: id },
        { headers: this.headers }
      )
      .pipe(catchError(this.errorMgmt));
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
