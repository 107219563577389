import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
//import { Plan } from './plan.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private userService: UserService,private http: HttpClient) { }

  headers = new HttpHeaders({
    Accept: 'application/json',
    Authorization: `Bearer ${this.userService.getToken()}`
  }); 


  createLanguage(data): Observable<any> {
    //console.log(this.headers);
    return this.http.post(environment.apiBaseUrl+'/language/insert_language', data,{headers: this.headers})
      .pipe(
        catchError(this.errorMgmt)
      )
  }

  getLanguages() {
    console.log(this.headers);
    return this.http.get(environment.apiBaseUrl+'/language/get_language',{headers: this.headers});
  }

  getLanguage(id): Observable<any> {
    return this.http.post(environment.apiBaseUrl+'/language/get_language/'+id, {headers: this.headers}).pipe(
      map((res: Response) => {
        return res || {}
      }),
      catchError(this.errorMgmt)
    )
  }

  updateLanguage(id, data): Observable<any> {
    //let url = `${this.baseUri}/updatecategory/${id}`;
    return this.http.post(environment.apiBaseUrl+'/language/update_language/'+id, data, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    )
  }


  deleteLanguage(id): Observable<any> {
    //let url = `${this.baseUri}/deletecategory/${id}`;
    return this.http.post(environment.apiBaseUrl+'/language/delete_language',{language_id:id}, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    )
  }

  
   errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {

      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
   }
}
