import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { MovieService } from '../../shared/movie.service';
import { LanguageService } from '../../shared/language.service';
import { CategoryService } from '../../shared/category.service';

@Component({
  selector: 'app-addmovie',
  templateUrl: './addmovie.component.html',
  styleUrls: ['./addmovie.component.css']
})
export class AddmovieComponent implements OnInit {

  submitted = false;
  preview:string;
  Language:any = [];
  Category:any = [];
  previewTrailer:string;
  previewMain:string;
  movieForm :FormGroup;
  constructor(
    private router: Router,
    private ngZone: NgZone,
    public fb :FormBuilder,
    private language : LanguageService,
    private category : CategoryService,
    private apiService : MovieService
    
    ) { 
      this.mainForm();
      this.readCategory();
      this.readLanguage();
    }

  ngOnInit() {}
  mainForm(){
    this.movieForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, ]],
      category: ['', [Validators.required]],
      language: ['', [Validators.required]],
      release_date: ['', [Validators.required]],
      director_name: ['', [Validators.required, ]],
      time_duration: ['', [Validators.required, ]],
      video_cover_image: ['', [Validators.required, ]],
      trailer_video: ['', [Validators.required, ]],
      main_video: ['', [Validators.required, ]]
    })
  }

  readLanguage(){
    this.language.getLanguages().subscribe((data) => {
     this.Language = data['data'];
     //console.log(this.Language)
    })    
  }

  readCategory(){
    this.category.getCategorys().subscribe((data) => {
     this.Category = data['data'];
     //console.log(this.Category)
    })    
  }

  UpdateLang(e) {
    this.movieForm.get('language').setValue(e, {
      onlySelf: true
    })
  }
  UpdateCat(e) {
    this.movieForm.get('category').setValue(e, {
      onlySelf: true
    })
  }

  get myForm(){
    return this.movieForm.controls;
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    this.movieForm.patchValue({
      video_cover_image: file
    });
    this.movieForm.get('video_cover_image').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
      //console.log(this.preview);

    }
    reader.readAsDataURL(file)
  }

  uploadTrailer(event){
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    this.movieForm.patchValue({
      trailer_video: file
    });
    this.movieForm.get('trailer_video').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.previewTrailer = reader.result as string;
      //console.log(this.preview);

    }
    reader.readAsDataURL(file)
  }

  uploadMain(event){
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    this.movieForm.patchValue({
      main_video: file
    });
    this.movieForm.get('main_video').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.previewMain = reader.result as string;
      //console.log(this.preview);

    }
    reader.readAsDataURL(file)
  }


  onSubmit() {
    this.submitted = true;
    console.log("movi",this.movieForm.value.video_cover_image);
    console.log(this.movieForm.value);
    if (!this.movieForm.valid) {
      return false;
    } else {
      
      this.apiService.createMovie(this.movieForm.value).subscribe(
        (res) => {
          console.log(res);
          if(res['status'] ===true){
            alert('Movie Added successfully!')
            this.ngZone.run(() => this.router.navigateByUrl('/movie'))
          }
          else
          {
            alert('Something went wrong!');
          }
          
        }, (error) => {
          console.log(error);
        });
    }
  }


}
