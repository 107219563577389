import { Component, OnInit } from '@angular/core';
import { PlanService } from '../../shared/plan.service';
import { UsermanageService } from '../../shared/usermanage.service';
import { MovieService } from '../../shared/movie.service';
import { CmsService } from '../../shared/cms.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  totalUser:string;
  totalPlan:string;
  totalMovie:string;
  totalPage:string;

  constructor(private apiService:MovieService,private planservice:PlanService,private cmsPages:CmsService,private userList:UsermanageService) { }

  ngOnInit() {
    this.totalMovies();
    this.totalUsers();
    this.totalPlans();
    this.totalPages();
  }

  totalUsers(){
    this.userList.getUsers().subscribe((data) => {
      this.totalUser = data['data'].length;
      //console.log(this.totalUser);
    })
  }

  totalMovies(){
    this.apiService.getMovies().subscribe((data) => {
     this.totalMovie = data['data'].length;
     //console.log(this.totalMovie)
    })    
  }

  totalPlans(){
    this.planservice.getPlans().subscribe((data) => {
      this.totalPlan = data['data'].length;
      //console.log(this.totalPlan);
    })
  }

  totalPages(){
    this.cmsPages.getCmss().subscribe((data) => {
      this.totalPage = data['data'].length;
      //console.log(this.totalPage);
    })
  }

}
