import { Component, OnInit } from "@angular/core";
import { JoinRequestService } from "../../shared/joinrequest.service";
import { JoinRequest } from "../../shared/joinrequest.model";

@Component({
  selector: "app-join-request",
  templateUrl: "./joinrequest.component.html",
  styleUrls: ["./joinrequest.component.css"],
})
export class JoinRequestComponent implements OnInit {
  usr: JoinRequest[] = [];

  constructor(private apiService: JoinRequestService) {
    this.getAllUser();
  }

  ngOnInit() {}

  getAllUser() {
    this.apiService.getAllUser().subscribe((data) => {
      this.usr = data["data"];
      console.log(this.usr);
    });
  }
}
