import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BannerserviceService } from './../../shared/bannerservice.service';
import { Banner } from './../../shared/category.model';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MovieService } from '../../shared/movie.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-editbanner',
  templateUrl: './editbanner.component.html',
  styleUrls: ['./editbanner.component.css']
})
export class EditbannerComponent implements OnInit {

  banner:any =[];
  imgs:String;
  image_url = environment.apiMainUrl;
  EditMovie: any = []
  submitted = false;
  preview :string;
  editbannerform: FormGroup;
  BannerData: Banner[];


  constructor(
    public fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private apiService: BannerserviceService,
    private movieList: MovieService,
    private router: Router
  ) { this.readMovie()}

  ngOnInit() {
    this.updateBanner();
    let id = this.actRoute.snapshot.paramMap.get('id');
    this.getBanner(id);
    this.editbannerform = this.fb.group({
      movie_id: ['', [Validators.required]],
      banner_image: ['', [Validators.required]],
    })
  }

  readMovie(){
    this.movieList.getMovies().subscribe((data) => {
     this.EditMovie = data['data'];
     //console.log("banner",this.EmployeeProfile)
    })
  }

  updateMovieid(e) {
    this.editbannerform.get('movie_id').setValue(e, {
      onlySelf: true
    })
  }

  get myForm() {
    return this.editbannerform.controls;
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.editbannerform.patchValue({
      banner_image: file
    });
    this.editbannerform.get('banner_image').updateValueAndValidity()

    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;

    }
    reader.readAsDataURL(file)
  }
  getBanner(id) {
    this.apiService.getBanners().subscribe((data) => {
      this.banner = data['data'];
      for(let bannerData of this.banner){
        //console.log(bannerData);
        if(bannerData._id ===id){
          console.log('matched',bannerData.image)
          this.imgs = bannerData.image;
          //alert(this.imgs)
          this.editbannerform.setValue({
            movie_id: bannerData.movie_id,
            banner_image: bannerData.image,
          });
        }
      }
     })
  }

  updateBanner() {
    this.editbannerform = this.fb.group({
      movie_id: ['', [Validators.required]],
      banner_image: ['', [Validators.required]],
    })
  }
  onSubmit() {
    this.submitted = true;
    if (!this.editbannerform.valid) {
      return false;
    } else {
      if (window.confirm('Do you want to update the data?')) {
        let id = this.actRoute.snapshot.paramMap.get('id');
        this.apiService.updateBanner(id,
        this.editbannerform.value.movie_id,
        this.editbannerform.value.banner_image
        ).subscribe(res => {
            this.router.navigateByUrl('/banner');
            //console.log('Content updated successfully!')
          }, (error) => {
            console.log(error)
          })
      }
    }
  }

}
