import { Component, OnInit, NgZone } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { from } from "rxjs";
import { Router } from "@angular/router";
import { FaqService } from "../../shared/faq.service";

@Component({
  selector: "app-addfaq",
  templateUrl: "./addfaq.component.html",
  styleUrls: ["./addfaq.component.css"],
})
export class AddfaqComponent implements OnInit {
  submitted = false;
  cmsForm: FormGroup;
  constructor(
    private router: Router,
    private ngZone: NgZone,
    public fb: FormBuilder,
    private serviceApi: FaqService
  ) {
    this.mainForm();
  }

  ngOnInit() {}
  mainForm() {
    this.cmsForm = this.fb.group({
      name: ["", [Validators.required]],
      description: ["", [Validators.required]],
    });
  }
  get myForm() {
    return this.cmsForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (!this.cmsForm.valid) {
      return false;
    } else {
      //console.log(this.planApi.createPlan(this.planForm.value));
      this.serviceApi.createfaq(this.cmsForm.value).subscribe(
        (res) => {
          console.log(res);
          //alert('Category successfully created!')
          if (res["status"] === true) {
            this.ngZone.run(() => this.router.navigateByUrl("/faqs"));
          } else {
            alert("Something went wrong!");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}
