import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class MovieService {

  constructor(private userService: UserService,private http: HttpClient) { }

  headers = new HttpHeaders({
    Accept: 'application/json',
    Authorization: `Bearer ${this.userService.getToken()}`
  });


  createMovie(data): Observable<any> {
    var formData: any = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("category", data.category);
    formData.append("language", data.language);
    formData.append("release_date", data.release_date);
    formData.append("director_name", data.director_name);
    formData.append("time_duration", data.time_duration);
    formData.append("video_cover_image", data.video_cover_image);
    formData.append("trailer_video", data.trailer_video);
    formData.append("main_video", data.main_video);
    //console.log(this.headers);
    return this.http.post(environment.apiBaseUrl+'/movie/upload_movie', formData,{headers: this.headers})
      .pipe(
        catchError(this.errorMgmt)
      )
  }

  getMovies() {
    return this.http.post(environment.apiBaseUrl+'/movie/get_all_movie',{headers: this.headers});
  }

  getMovie(id): Observable<any> {
    return this.http.post(environment.apiBaseUrl+'/movie/view_movie',{movie_id:id}, {headers: this.headers}).pipe(
      map((res: Response) => {
        return res || {}
      }),
      catchError(this.errorMgmt)
    )
  }

  updateMovie(id, data): Observable<any> {
    //let url = `${this.baseUri}/updatecategory/${id}`;
    //console.log(data);
    var formData: any = new FormData();
    formData.append("folder", data.folder);
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("category", data.category);
    formData.append("language", data.language);
    formData.append("release_date", data.release_date);
    formData.append("director_name", data.director_name);
    formData.append("time_duration", data.time_duration);
    formData.append("video_cover_image", data.video_cover_image);
    formData.append("trailer_video", data.trailer_video);
    formData.append("main_video", data.main_video);
    formData.append("price", data.price);
    formData.append("duration", data.duration);
    return this.http.post(environment.apiBaseUrl+'/movie/update_movie/'+id, formData, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    )
  }


  deleteMovie(id): Observable<any> {
    //let url = `${this.baseUri}/deletecategory/${id}`;
    return this.http.post(environment.apiBaseUrl+'/movie/delete_movie/',{movie_id:id}, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    )
  }


   errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {

      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
   }

}

