import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { LanguageService } from './../../shared/language.service';

@Component({
  selector: 'app-addlanguage',
  templateUrl: './addlanguage.component.html',
  styleUrls: ['./addlanguage.component.css']
})
export class AddlanguageComponent implements OnInit {

  submitted = false;
  languageForm :FormGroup;
  constructor(
    private router: Router,
    private ngZone: NgZone,
    public fb :FormBuilder,
    private serviceApi : LanguageService
    
    ) { 
      this.mainForm();
    }

  ngOnInit() {}
  mainForm(){
    this.languageForm = this.fb.group({
      name: ['', [Validators.required]]
    })
  }
  get myForm(){
    return this.languageForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (!this.languageForm.valid) {
      return false;
    } else {
      this.serviceApi.createLanguage(this.languageForm.value).subscribe(
        (res) => {
          console.log(res);
          if(res['status'] ===true){
            this.ngZone.run(() => this.router.navigateByUrl('/language'))
          }
          else
          {
            alert('Something went wrong!');
          }
          
        }, (error) => {
          console.log(error);
        });
    }
  }

}
