import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CmsService } from './../../shared/cms.service';
import { Cms } from './../../shared/category.model';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'app-editcms',
  templateUrl: './editcms.component.html',
  styleUrls: ['./editcms.component.css']
})
export class EditcmsComponent implements OnInit {

  cms:any =[];
  submitted = false;
  editCmsForm: FormGroup;
  CmsData: Cms[];


  constructor(
    public fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private apiService: CmsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.updateCms();
    let id = this.actRoute.snapshot.paramMap.get('id');
    this.getCms(id);
    this.editCmsForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, ]]
    })
  }


  get myForm() {
    return this.editCmsForm.controls;
  }
  getCms(id) {
    this.apiService.getCmss().subscribe((data) => {
      this.cms = data['data'];
      for(let cmsData of this.cms){
        if(cmsData._id ===id){
          this.editCmsForm.setValue({
            name: cmsData.name,
            description: cmsData.description
          });
        }
      }
     }) 
  }

  updateCms() {
    this.editCmsForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, ]]
    })
  }
  onSubmit() {
    this.submitted = true;
    if (!this.editCmsForm.valid) {
      return false;
    } else {
      if (window.confirm('Do you want to update the data?')) {
        let id = this.actRoute.snapshot.paramMap.get('id');
        this.apiService.updateCms(id, this.editCmsForm.value)
          .subscribe(res => {            
            this.router.navigateByUrl('/cms');
            //console.log('Content updated successfully!')
          }, (error) => {
            console.log(error)
          })
      }
    }
  }

}
