import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from './../../shared/language.service';
import { Language } from './../../shared/category.model';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'app-editlanguage',
  templateUrl: './editlanguage.component.html',
  styleUrls: ['./editlanguage.component.css']
})
export class EditlanguageComponent implements OnInit {

  language:any =[];
  submitted = false;
  editLangForm: FormGroup;
  LanguageData: Language[];


  constructor(
    public fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private apiService: LanguageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.updateLanguage();
    let id = this.actRoute.snapshot.paramMap.get('id');
    this.getLanguage(id);
    this.editLangForm = this.fb.group({
      name: ['', [Validators.required]]
    })
  }


  get myForm() {
    return this.editLangForm.controls;
  }
  getLanguage(id) {
    this.apiService.getLanguages().subscribe((data) => {
      this.language = data['data'];
      for(let languageData of this.language){
        //console.log(planData._id);
        if(languageData._id ===id){
          //console.log(planData)
          this.editLangForm.setValue({
            name: languageData.name
          });
        }
      }
     }) 
  }

  updateLanguage() {
    this.editLangForm = this.fb.group({
      name: ['', [Validators.required]]
    })
  }
  onSubmit() {
    this.submitted = true;
    if (!this.editLangForm.valid) {
      return false;
    } else {
      if (window.confirm('Do you want to update the data?')) {
        let id = this.actRoute.snapshot.paramMap.get('id');
        this.apiService.updateLanguage(id, this.editLangForm.value)
          .subscribe(res => {            
            this.router.navigateByUrl('/language');
            //console.log('Content updated successfully!')
          }, (error) => {
            console.log(error)
          })
      }
    }
  }

}
