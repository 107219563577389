import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FaqService } from "../../shared/faq.service";
// import { Cms } from "../../shared/category.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-editfaq",
  templateUrl: "./editfaq.component.html",
  styleUrls: ["./editfaq.component.css"],
})
export class EditFaqComponent implements OnInit {
  cms: any = {};
  submitted = false;
  editCmsForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private apiService: FaqService,
    private router: Router
  ) {}

  ngOnInit() {
    this.updatefaq();
    let id = this.actRoute.snapshot.paramMap.get("id");
    this.getfaqs(id);
    this.editCmsForm = this.fb.group({
      name: ["", [Validators.required]],
      description: ["", [Validators.required]],
    });
  }

  get myForm() {
    return this.editCmsForm.controls;
  }
  getfaqs(id: string) {
    this.apiService.getfaq(id).subscribe((data) => {
      console.log(data);
      this.cms = data["data"];
      this.editCmsForm.setValue({
        name: this.cms.name,
        description: this.cms.description,
      });
    });
  }

  updatefaq() {
    this.editCmsForm = this.fb.group({
      name: ["", [Validators.required]],
      description: ["", [Validators.required]],
    });
  }
  onSubmit() {
    this.submitted = true;
    if (!this.editCmsForm.valid) {
      return false;
    } else {
      if (window.confirm("Do you want to update the data?")) {
        let id = this.actRoute.snapshot.paramMap.get("id");
        this.apiService.updatefaq(id, this.editCmsForm.value).subscribe(
          (res) => {
            this.router.navigateByUrl("/faqs");
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }
}
