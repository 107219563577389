import { Component, OnInit } from '@angular/core';
import { CategoryService } from './../../shared/category.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  Category:any = [];
  successmsg = false;

  constructor(private apiService: CategoryService) {
    this.readCategory();
   }

  ngOnInit() {
  }

  readCategory(){
    this.apiService.getCategorys().subscribe((data) => {
     this.Category = data['data'];
     console.log(this.Category)
    })    
  }

  removeCategory(Category, index) {
    if(window.confirm('Are you sure?')) {
        this.apiService.deleteCategory(Category._id).subscribe((data) => {
          this.Category.splice(index,1);
          this.successmsg = true;
        }
      )    
    }
  }


}
