import { Component, OnInit, NgZone } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Movie } from "./../../shared/movie.model";
import { MovieService } from "../../shared/movie.service";
import { LanguageService } from "../../shared/language.service";
import { CategoryService } from "../../shared/category.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-editmovie",
  templateUrl: "./editmovie.component.html",
  styleUrls: ["./editmovie.component.css"],
})
export class EditmovieComponent implements OnInit {
  submitted = false;
  image_url = environment.apiMainUrl;
  movie: any = [];
  cover_img: string;
  trailer_vdo: string;
  main_vdo: string;
  Language: any = [];
  Category: any = [];
  preview: string;
  previewTrailer: string;
  previewMain: string;
  editmovieForm: FormGroup;
  MovieData: Movie[];

  folderUrl = environment.apiMainUrl;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    public fb: FormBuilder,
    public actRoute: ActivatedRoute,
    private apiService: MovieService,
    private langList: LanguageService,
    private catList: CategoryService
  ) {
    this.readLanguage();
    this.readCategory();
  }

  ngOnInit() {
    this.updateMovie();
    let id = this.actRoute.snapshot.paramMap.get("id");
    this.getMovie(id);
    this.editmovieForm = this.fb.group({
      folder: [""],
      name: ["", [Validators.required]],
      price: [0, [Validators.required, Validators.min(1)]],
      duration: [0, [Validators.required, Validators.min(60)]],
      description: ["", [Validators.required]],
      category: ["", [Validators.required]],
      language: ["", [Validators.required]],
      release_date: ["", [Validators.required]],
      director_name: ["", [Validators.required]],
      time_duration: ["", [Validators.required]],
      video_cover_image: ["", [Validators.required]],
      trailer_video: ["", [Validators.required]],
      main_video: ["", [Validators.required]],
    });
  }

  getMovie(id) {
    this.apiService.getMovies().subscribe((data) => {
      this.movie = data["data"];
      for (let movieData of this.movie) {
        if (movieData._id === id) {
          console.log(movieData.video_cover_image);
          this.cover_img = movieData.video_cover_image;
          this.trailer_vdo = movieData.trailer_video;
          this.main_vdo = movieData.main_video;
          this.editmovieForm.setValue({
            folder: movieData.folder,
            name: movieData.name,
            price: movieData.price,
            duration: movieData.duration,
            description: movieData.description,
            category: movieData.category,
            language: movieData.language,
            release_date: new Date(movieData.release_date).toLocaleDateString(
              "en-CA"
            ),
            director_name: movieData.director_name,
            time_duration: movieData.time_duration,
            video_cover_image: movieData.video_cover_image,
            trailer_video: movieData.trailer_video,
            main_video: movieData.main_video,
          });
        }
      }
    });
  }

  readLanguage() {
    this.langList.getLanguages().subscribe((data) => {
      this.Language = data["data"];
      //console.log(this.Language)
    });
  }

  readCategory() {
    this.catList.getCategorys().subscribe((data) => {
      this.Category = data["data"];
      //console.log(this.Category)
    });
  }

  UpdateLang(e) {
    this.editmovieForm.get("language").setValue(e, {
      onlySelf: true,
    });
  }
  UpdateCat(e) {
    this.editmovieForm.get("category").setValue(e, {
      onlySelf: true,
    });
  }

  updateMovie() {
    this.editmovieForm = this.fb.group({
      folder: [""],
      name: ["", [Validators.required]],
      price: [0, [Validators.required, Validators.min(1)]],
      duration: [0, [Validators.required, Validators.min(60)]],
      description: ["", [Validators.required]],
      category: ["", [Validators.required]],
      language: ["", [Validators.required]],
      release_date: ["", [Validators.required]],
      director_name: ["", [Validators.required]],
      time_duration: ["", [Validators.required]],
      video_cover_image: ["", [Validators.required]],
      trailer_video: ["", [Validators.required]],
      main_video: ["", [Validators.required]],
    });
  }

  get myForm() {
    return this.editmovieForm.controls;
  }

  uploadFile(event) {
    if (!event.target.files.length) return;
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    // this.editmovieForm.patchValue({
    //   video_cover_image: file,
    // });
    // this.editmovieForm.get("video_cover_image").updateValueAndValidity();
    // const reader = new FileReader();
    // reader.onload = () => {
    //   this.preview = reader.result as string;
    //   //console.log(this.preview);
    // };
    // reader.readAsDataURL(file);

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.onload = () => {
        if (image.width !== 1270 && image.height !== 714) {
          alert("Please select the picture with mention size");
          return false;
        }
        this.preview = reader.result as string;
        this.editmovieForm.patchValue({ video_cover_image: file });
        this.editmovieForm.get("video_cover_image").updateValueAndValidity();
      };
      image.src = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  uploadTrailer(event) {
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    this.editmovieForm.patchValue({
      trailer_video: file,
    });
    this.editmovieForm.get("trailer_video").updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.previewTrailer = reader.result as string;
      //console.log(this.preview);
    };
    reader.readAsDataURL(file);
  }

  uploadMain(event) {
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    this.editmovieForm.patchValue({
      main_video: file,
    });
    this.editmovieForm.get("main_video").updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.previewMain = reader.result as string;
      //console.log(this.preview);
    };
    reader.readAsDataURL(file);
  }

  onSubmit() {
    this.submitted = true;
    // console.log("movi",this.editmovieForm.value.video_cover_image);
    // console.log(this.editmovieForm.value);
    if (!this.editmovieForm.valid) {
      return false;
    } else {
      let id = this.actRoute.snapshot.paramMap.get("id");
      this.apiService.updateMovie(id, this.editmovieForm.value).subscribe(
        (res) => {
          console.log(res);
          if (res["error"] === false) {
            this.ngZone.run(() => this.router.navigateByUrl("/movie"));
            alert("Movie Updated successfully!");
          } else {
            alert("Something went wrong!");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}
