import { Component, OnInit } from "@angular/core";
import { UsermanageService } from "../../shared/usermanage.service";

@Component({
  selector: "app-usermanage",
  templateUrl: "./usermanage.component.html",
  styleUrls: ["./usermanage.component.css"],
})
export class UsermanageComponent implements OnInit {
  User: any = [];
  successmsg = false;

  constructor(private apiService: UsermanageService) {
    this.readUserList();
  }

  ngOnInit() {}

  readUserList() {
    this.apiService.getUsers().subscribe((data) => {
      this.User = data["data"];
      //  console.log(this.User)
      const arr = [];
      for (let user of this.User) {
        let row = user;
        row.is_active_subscription = "Na"
        if (row.video_subscription) {
          row.is_active_subscription =
            new Date(row.video_subscription.expires_at).getTime() >
            new Date().getTime()
              ? "expires on"
              : "Na";
        }
        arr.push(row);
      }
      this.User = arr;
    });
  }

  removeUser(id, index) {
    if (window.confirm("Are you sure?")) {
      this.apiService.deleteUser(id).subscribe((data) => {
        this.User.splice(index, 1);
        this.successmsg = true;
      });
    }
  }
}
