import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { PlanService } from './../../shared/plan.service';

@Component({
  selector: 'app-addplan',
  templateUrl: './addplan.component.html',
  styleUrls: ['./addplan.component.css']
})
export class AddplanComponent implements OnInit {

  submitted = false;
  planForm :FormGroup;
  constructor(
    private router: Router,
    private ngZone: NgZone,
    public fb :FormBuilder,
    private planApi : PlanService
    
    ) { 
      this.mainForm();
    }

  ngOnInit() {}
  mainForm(){
    this.planForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, ]],
      plan_duration_for: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      plan_duration: ['', [Validators.required, ]],
      price: ['', [Validators.required, Validators.pattern("^[0-9]*$"),Validators.minLength(3)]],
      currency_type: ['', [Validators.required, ]]
    })
  }
  get myForm(){
    return this.planForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (!this.planForm.valid) { 
      alert('not valid')
      return false;
    } else {
      //console.log(this.planApi.createPlan(this.planForm.value));
      this.planApi.createPlan(this.planForm.value).subscribe(
        (res) => {
          //console.log(res);
          if(res['status'] ===true){
            this.ngZone.run(() => this.router.navigateByUrl('/subscription'));
            alert('Data added successfully!');
          }
          else
          {
            alert('Something went wrong!');
          }
          
        }, (error) => {
          console.log(error);
        });
    }
  }

}
