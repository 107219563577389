import { Component, OnInit } from '@angular/core';
import { LanguageService } from './../../shared/language.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  Language:any = [];
  successmsg = false;

  constructor(private apiService: LanguageService) {
    this.readLanguage();
   }

  ngOnInit() {
  }

  readLanguage(){
    this.apiService.getLanguages().subscribe((data) => {
     this.Language = data['data'];
     //console.log(this.Language)
    })    
  }

  removeLanguage(Language, index) {
    if(window.confirm('Are you sure?')) {
        this.apiService.deleteLanguage(Language._id).subscribe((data) => {
          this.Language.splice(index,1);
          this.successmsg = true;
        }
      )    
    }
  }

}
