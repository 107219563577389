import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  selectedUser: User = {
    email: '',
    password: ''
  };

  //noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'False' }) };
  acceptheader = { headers: new HttpHeaders({Accept: 'application/json'}) };
 

  constructor(private http: HttpClient) { }

  //HttpMethods

  postUser(user: User){
    return this.http.post(environment.apiBaseUrl+'/register',user,this.acceptheader);
  }

  login(authCredentials) {
    //alert(environment.apiBaseUrl + '/login');
    //console.log(authCredentials);
    return this.http.post(environment.apiBaseUrl + '/userauth/login', authCredentials,this.acceptheader);
  }

  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/userauth/get_profile_details');
  }


  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  deleteToken() {
    localStorage.removeItem('token');
  }

  getUserPayload() {
    const token = this.getToken();
    //console.log(token);
    if (token) {
      const userPayload = atob(token.split('.')[1]);
      return userPayload;
    }
    else
      return null;
  }

  isLoggedIn() {
    const userPayload = JSON.parse(this.getUserPayload());
    if (userPayload != null && userPayload._id)
      //return JSON.parse(userPayload).expire_at > Date.now() / 1000;
      return userPayload;
    else
      return false;
  }
}
