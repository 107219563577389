import { Component, OnInit } from "@angular/core";
import { FaqService } from "../../shared/faq.service";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"],
})
export class FaqComponent implements OnInit {
  Cms: any = [];

  constructor(private apiService: FaqService) {
    this.readFaq();
  }

  ngOnInit() {}

  readFaq() {
    this.apiService.getfaqs().subscribe((data) => {
      this.Cms = data["data"];
      console.log(this.Cms);
    });
  }

  removeFaq(_id: any, index: any) {
    if (window.confirm("Are you sure?")) {
      this.apiService.deletefaq(_id).subscribe((data) => {
        this.Cms.splice(index, 1);
      });
    }
  }
}
