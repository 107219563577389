import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { CategoryService } from './../../shared/category.service';

@Component({
  selector: 'app-addcategory',
  templateUrl: './addcategory.component.html',
  styleUrls: ['./addcategory.component.css']
})
export class AddcategoryComponent implements OnInit {

  submitted = false;
  categoryForm :FormGroup;
  constructor(
    private router: Router,
    private ngZone: NgZone,
    public fb :FormBuilder,
    private categoryApi : CategoryService
    
    ) { 
      this.mainForm();
    }

  ngOnInit() {}
  mainForm(){
    this.categoryForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, ]]
    })
  }
  get myForm(){
    return this.categoryForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (!this.categoryForm.valid) {
      return false;
    } else {
      //console.log(this.planApi.createPlan(this.planForm.value));
      this.categoryApi.createCategory(this.categoryForm.value).subscribe(
        (res) => {
          console.log(res);
          //alert('Category successfully created!')
          if(res['status'] ===true){
            this.ngZone.run(() => this.router.navigateByUrl('/category'))
          }
          else
          {
            this.submitted = false;
            alert(res['message']);
          }
          
        }, (error) => {
          console.log(error);
        });
    }
  }

}
