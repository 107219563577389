import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { BannerserviceService } from '../../shared/bannerservice.service';
import { MovieService } from '../../shared/movie.service';


@Component({
  selector: 'app-addbanner',
  templateUrl: './addbanner.component.html',
  styleUrls: ['./addbanner.component.css']
})
export class AddbannerComponent implements OnInit {

  preview: string;
  Movie:any = [];
  bannerform: FormGroup;
  percentDone: any = 0;
  banners = [];
  submitted = false;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private ngZone: NgZone,
    private getMovieList: MovieService,
    public fileUploadService: BannerserviceService
  ) {
    this.readMovie();
    this.bannerform = this.fb.group({
      movie_id: ['',[Validators.required]],
      banner_image: ['',[Validators.required]]
    })
  }

  ngOnInit() { }

  readMovie(){
    this.getMovieList.getMovies().subscribe((data) => {
     this.Movie = data['data'];
     //console.log("banner",this.Movie)
    })    
  }


  mainForm(){
    this.bannerform = this.fb.group({
      movie_id: ['', [Validators.required]]
    })
  }
  get myForm(){
    return this.bannerform.controls;
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    this.bannerform.patchValue({
      banner_image: file
    });
    this.bannerform.get('banner_image').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
      //console.log(this.preview);

    }
    reader.readAsDataURL(file)
  }

//   onSubmit() {
//     this.submitted = true;
//     if(!this.bannerform.valid) {
//       alert('Please fill all the required fields to create a super hero!')
//       return false;
//     } else{
//     console.log(this.bannerform.value);
//     this.fileUploadService.createBanner(
//       this.bannerform.value.movie_id,
//       this.bannerform.value.banner_image
//     ).subscribe(
//       (event: HttpEvent<any>) => {
//       switch (event.type) {
//         case HttpEventType.Sent:
//           console.log('Request has been made!');
//           break;
//         case HttpEventType.ResponseHeader:
//           console.log('Response header has been received!');
//           break;
//         case HttpEventType.UploadProgress:
//           this.percentDone = Math.round(event.loaded / event.total * 100);
//           console.log(`Uploaded! ${this.percentDone}%`);
//           break;
//         case HttpEventType.Response:
//           console.log('User successfully created!', event.body);
//           this.percentDone = false;
//           this.ngZone.run(() => this.router.navigateByUrl('/cms'))
//          // this.router.navigate(['banner'])
//       }
//     })
    
//   }
// }
  
onSubmit() {
  this.submitted = true;
  if (!this.bannerform.valid) {
    return false;
  } else {
    //console.log(this.planApi.createPlan(this.planForm.value));
    //console.log(this.bannerform.value.banner_image);
    this.fileUploadService.createBanner(
            this.bannerform.value.movie_id,
            this.bannerform.value.banner_image
          ).subscribe(
      (res) => {
        //console.log(res);
        if(res['status'] ===true){
          this.ngZone.run(() => this.router.navigateByUrl('/banner'));
          alert('Banner successfully created!')
        }
        else
        {
          alert('Something went wrong!');
        }
        
      }, (error) => {
        console.log(error);
      });
  }
}

}
