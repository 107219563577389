import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
//import { Plan } from './plan.model';
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class UsermanageService {
  // headers = new HttpHeaders().set('Accept', 'application/json');

  constructor(private userService: UserService, private http: HttpClient) {}

  headers = new HttpHeaders({
    Accept: "application/json",
    Authorization: `Bearer ${this.userService.getToken()}`,
  });

  createPlan(data): Observable<any> {
    //console.log(this.headers);
    return this.http
      .post(environment.apiBaseUrl + "/plan/insert_plan", data, {
        headers: this.headers,
      })
      .pipe(catchError(this.errorMgmt));
  }

  getUsers() {
    //console.log(this.headers);
    return this.http.get(environment.apiBaseUrl + "/userauth/get_users", {
      headers: this.headers,
    });
  }

  getUser(id): Observable<any> {
    return this.http
      .get(environment.apiBaseUrl + "/userauth/current_subscription_plan", {
        headers: this.headers,
      })
      .pipe(
        map((res: Response) => {
          return res || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  updateUser(id, data): Observable<any> {
    //let url = `${this.baseUri}/updatecategory/${id}`;
    console.log(data);
    return this.http
      .post(
        environment.apiBaseUrl + "/userauth/active_inactive_user",
        { user_id: id, active: data.is_active },
        { headers: this.headers }
      )
      .pipe(catchError(this.errorMgmt));
  }

  deleteUser(id: any): Observable<any> {
    //let url = `${this.baseUri}/deletecategory/${id}`;
    return this.http
      .post(
        environment.apiBaseUrl + "/userauth/delete_user",
        { user_id: id },
        { headers: this.headers }
      )
      .pipe(catchError(this.errorMgmt));
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
