import { Component, OnInit } from '@angular/core';
import { CmsService } from './../../shared/cms.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit {

  Cms:any = [];

  constructor(private apiService: CmsService) {
    this.readCms();
   }

  ngOnInit() {
  }

  readCms(){
    this.apiService.getCmss().subscribe((data) => {
     this.Cms = data['data'];
     console.log(this.Cms)
    })    
  }

  removeCms(cms, index) {
    if(window.confirm('Are you sure?')) {
        this.apiService.deleteCms(cms._id).subscribe((data) => {
          this.Cms.splice(index,1);
        }
      )    
    }
  }

}
