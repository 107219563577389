import { Component, OnInit } from '@angular/core';
import { PlanService } from './../../shared/plan.service';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {

  Plan:any = [];
  successmsg = false;

  constructor(private apiService: PlanService) {
    this.readPlan();
   }

  ngOnInit() {
  }

  readPlan(){
    this.apiService.getPlans().subscribe((data) => {
     this.Plan = data['data'];
     console.log(this.Plan)
    })    
  }

  removePlan(Plan, index) {
    if(window.confirm('Are you sure?')) {
        this.apiService.deletePlan(Plan._id).subscribe((data) => {
          this.Plan.splice(index,1);
          this.successmsg = true;
        }
      )    
    }
  }

}
