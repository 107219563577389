import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsermanageService } from './../../shared/usermanage.service';
import { Usermanage } from './../../shared/category.model';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {

  usermanage:any =[];
  submitted = false;
  editUsermanageForm: FormGroup;
  UsermanageData: Usermanage[];


  constructor(
    public fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private apiService: UsermanageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.updateUsermanage();
    let id = this.actRoute.snapshot.paramMap.get('id');
    this.getUsermanage(id);
    this.editUsermanageForm = this.fb.group({
      is_active: ['', [Validators.required]]
    })
  }


  get myForm() {
    return this.editUsermanageForm.controls;
  }
  getUsermanage(id) {
    this.apiService.getUsers().subscribe((data) => {
      this.usermanage = data['data'];
      for(let UsermanageData of this.usermanage){
        //console.log(planData._id);
        if(UsermanageData._id ===id){
          //console.log(UsermanageData)
          this.editUsermanageForm.setValue({
            is_active: UsermanageData.is_active
          });
        }
      }
     }) 
    // this.apiService.getPlan(id).subscribe(data => {
    //   this.editPlanForm.setValue({
    //     name: data['name'],
    //     description: data['description'],
    //     plan_duration_for: data['plan_duration_for'],
    //     plan_duration: data['plan_duration'],
    //     price: data['price'],
    //     currency_type: data['currency_type']
    //   });
    // });
  }

  updateUsermanage() {
    this.editUsermanageForm = this.fb.group({
      is_active: ['', [Validators.required]]
    })
  }
  onSubmit() {
    this.submitted = true;
    if (!this.editUsermanageForm.valid) {
      return false;
    } else {
      if (window.confirm('Do you want to update the data?')) {
        let id = this.actRoute.snapshot.paramMap.get('id');
        this.apiService.updateUser(id, this.editUsermanageForm.value)
          .subscribe(res => {            
            this.router.navigateByUrl('/usermanage');
            //console.log('Content updated successfully!')
          }, (error) => {
            console.log(error)
          })
      }
    }
  }

}
