import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class JoinRequestService {
  constructor(private userService: UserService, private http: HttpClient) {}

  headers = new HttpHeaders({
    Accept: "application/json",
    Authorization: `Bearer ${this.userService.getToken()}`,
  });

  getAllUser() {
    return this.http.post(environment.apiBaseUrl + "/survey/get-users", {
      headers: this.headers,
    });
  }
}
