import { Component, OnInit } from '@angular/core';
import { BannerserviceService } from '../../shared/bannerservice.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  Banner:any = [];
  successmsg = false;
  image_url = environment.apiMainUrl;

  constructor(private apiService: BannerserviceService) {
    this.readBanner();
   }

  ngOnInit() {
  }


  readBanner(){
    this.apiService.getBanners().subscribe((data) => {
     this.Banner = data['data'];
     console.log("banner",this.Banner)
    })
  }

  removeBanner(Banner, index) {
    if(window.confirm('Are you sure?')) {
        this.apiService.deleteBanner(Banner._id).subscribe((data) => {
          this.Banner.splice(index,1);
          this.successmsg = true
        }
      )
    }
  }

}
