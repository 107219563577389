import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
   submitted = false;
   signInForm:FormGroup;
  constructor(private userService: UserService, private router : Router, public fb :FormBuilder) { }

  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  serverErrorMessages: string;
  loder = false;
  ngOnInit() {
    if(this.userService.isLoggedIn())
    this.router.navigate(['/dashboard']);
    this.mainForm();
  }
  mainForm(){
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    })
  }
  get myForm(){
    return this.signInForm.controls;
  }

  onSubmit(){
    this.submitted = true;
    if(!this.signInForm.valid){
       return false;
    }
    else{
      this.loder = true;
      this.userService.login(this.signInForm.value).subscribe(
        res => {
          //console.log(this.userService.setToken(res['token']));
          //alert('You are logged in Successfully!');
          this.userService.setToken(res['token']);
          this.router.navigate(['/dashboard']);
          if(res['status'] ===false){
            
            this.loder = false;
            this.serverErrorMessages = res['message'];
          }
        },
        err => {
          this.serverErrorMessages = err.error.message;
          //console.log(res);
        }
      );
    }

  }

}
